.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #FAFAFA;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 900px) {
  .ant-card-head-title {
    white-space: initial !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  
}
@media only screen and (max-width: 1200px) {
  .grid-container {
    display: block;
  }

  .grid-item {
    margin-bottom: 20px;
  }

  .grid-item:last-child {
    margin-bottom: 0;
  }
}

.grid-item {
  display: flex;
  align-items: stretch;
}

.grid-item .ant-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.present {
  height: 200px;
  left: 0;
  margin: 0 auto;
  perspective: 600px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  cursor: pointer;
}
.name {
  font-size: 1em;
  font-weight: 100;
  position: absolute;
  top: 50%;
  font-family: Montserrat;
  line-height: 2;
  width: 400px;
}
.instruction {
  bottom: -100px;
  left: 0;
  opacity: 1;
  position: absolute;
  text-align: center;
  transition: opacity 0.5s;
  width: 100%;
}
.rotate-container {
  animation: present-rotate 30s infinite linear;
  height: 100%;
  transform: rotateY(170deg);
  transform-style: preserve-3d;
}
@keyframes present-rotate {
  0% {
    transform: rotateY(0);
 }
  100% {
    transform: rotateY(360deg);
 }
}
.bottom, .front, .left, .back, .right {
  background-color: #e5ce81;
  border: 1px solid rgba(0, 0, 0, .2);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bottom {
  transform: translateY(50%) rotateX(90deg);
}
.front, .left, .back, .right {
  transition: transform 0.5s;
  transform-origin: bottom;
  transform-style: preserve-3d;
}
.front:after, .left:after, .back:after, .right:after, .lid-top:after, .lid-front:after, .lid-left:after, .lid-back:after, .lid-right:after {
  background-color: #f2e6c0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  content: '';
  height: 100%;
  left: calc(50% - 10px);
  position: absolute;
  transform: translateZ(0.1px);
  width: 20px;
}
.lid-top:before {
  background-color: #f2e6c0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  content: '';
  height: 20px;
  left: 0;
  position: absolute;
  top: calc(50% - 10px);
  transform: translateZ(0.1px);
  width: 100%;
}
.left {
  transform: translateX(-50%) rotateY(-90deg);
}
.back {
  transform: translateZ(-100px) rotateY(180deg) rotateX(0);
}
.right {
  transform: translateX(50%) rotateY(90deg);
}
.front {
  transform: translateZ(100px);
}
.lid {
  animation: lid-animation 3.5s 1s infinite;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
@keyframes lid-animation {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0);
 }
  5% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
 }
  10% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
 }
  15% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
 }
  20% {
    transform: translate3d(0, -10px, 5px) rotateX(-5deg);
 }
  25% {
    transform: translate3d(0, -10px, -5px) rotateX(5deg);
 }
  30% {
    transform: translate3d(0, 0, 0) rotateX(0);
 }
}
.lid-top, .lid-left, .lid-back, .lid-right, .lid-front {
  background-color: #e5ce81;
  border: 1px solid rgba(0, 0, 0, .2);
  left: -5px;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 210px;
}
.lid-top {
  height: 210px;
  top: -5px;
  transform: translateY(-50%) rotateX(90deg);
  transform-style: preserve-3d;
}
.lid-left, .lid-back, .lid-right, .lid-front {
  height: 40px;
  top: -5px;
  transform-style: preserve-3d;
}
.lid-left {
  transform: translateX(-50%) rotateY(-90deg);
}
.lid-back {
  transform: translateZ(-105px) rotateY(180deg);
}
.lid-right {
  transform: translateX(50%) rotateY(90deg);
}
.lid-front {
  transform: translateZ(105px);
}
.present:hover .lid {
  animation: none;
  transform: translate3d(0, -40px, -10px) rotateX(10deg);
}
.present.open .name {
  transform: translate3d(0, -50%, 10px) rotateY(1080deg) rotateX(10deg);
}
.present.open .instruction {
  opacity: 0;
}
.present.open .left {
  transform: translateX(-50%) rotateY(-90deg) rotateX(-90deg);
}
.present.open .back {
  transform: translateZ(-100px) rotateY(180deg) rotateX(-90deg);
}
.present.open .right {
  transform: translateX(50%) rotateY(90deg) rotateX(-90deg);
}
.present.open .front {
  transform: translateZ(100px) rotateX(-90deg);
}
.present.open .lid {
  animation: none;
  transform: translate3d(0, -120px, -120px) rotateX(50deg);
}
canvas {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
}

